
.ext-theme{
    --red: #ea2035;
    --pink: #ffafbc;
    --orange: #fe6b22;
    --ice-blue: #e9f0f6;
    --text-gray: #a8b1b6;
}

.theme {
    --primary: #497366;
    --secondary: #e5be8d;
    --green: #347668;
    --light-cream: #fef6e8;
    --white: #fff;
    --gray: #BCC1C5; 
    --dark: #383639;
    --black: #273745;
}



$primary: var(--primary);
$secondary: var(--secondary);
$green: var(--green);
$light-cream: var(--light-cream);
$white: var(--white);
$gray: var(--gray);
$dark: var(--dark);
$black: var(--black);

// bg - text - border colors
$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "green": $green,
    "light-cream": $light-cream,
    "white": $white,
    "gray": $gray,
    "dark": $dark,
    "black": $black,
);

@each $themeColour, $i in $theme-colors {
    .bg-#{$themeColour} {
        background-color: $i; // !important;
    }
    .text-#{$themeColour} {
        color: $i; // !important;
    }

    .ql-color-#{$themeColour} {
        color: $i; // !important;
    }
}
