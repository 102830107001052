
  
  /* HTML5 display-role reset for older browsers */
  
  article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block; }
  

  ol, ul {
    list-style: none; }
  
  blockquote, q {
    quotes: none; }
  
  blockquote {
    &:before, &:after {
      content: '';
      content: none; } }
  
  q {
    &:before, &:after {
      content: '';
      content: none; } }
  
  table {
    border-collapse: collapse;
    border-spacing: 0; }