@import "./../scss/mixins";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");
html,
body {
    font-family: "poppins", sans-serif;
    font-size: 13px;
    color: var(--dark);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-stroke: 0.45px;
    -webkit-text-stroke: 0.45px rgba(0, 0, 0, 0.1);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--dark);
}

h1 {
    font-size: 2.2rem;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.8rem;
}

h4 {
    font-size: 1.6rem;
}

h5 {
    font-size: 1.4rem;
}

h6 {
    font-size: 1.2rem;
}

.bbold {
    font-weight: 700!important;
}

::-webkit-scrollbar {
    width: 10px;
}


/* Track */

 ::-webkit-scrollbar-track {
    background: #a3a3a3;
}


/* Handle */

 ::-webkit-scrollbar-thumb {
    background: #f1f1f1;
    border: 2px solid #a3a3a3;
}


/* Handle on hover */

 ::-webkit-scrollbar-thumb:hover {
    background: #dedede;
}

.osb-card {
    display: block;
    position: relative;
    width: auto;
    height: auto;
    background: white;
    border-top-left-radius: 80px;
    border-bottom-right-radius: 80px;
    padding: 25px 30px 0 30px;
    margin: 25px;
    @include mq(760px) {
        margin: 5px;
    }
}